<script lang="ts">
  import { toast } from "../stores/toast.js";
  import type { Toast } from "../types/toast.js";
  import { scale } from "svelte/transition";
  import CheckIcon from "$icons/CheckIcon.svelte";
  import TrashcanClosedIcon from "$icons/TrashcanClosedIcon.svelte";
  import Close from "svelte-material-icons/Close.svelte";
  import WarningIcon from "$icons/WarningIcon.svelte";
  import type { ComponentType } from "svelte";

  export let t: Toast;

  // we dont need the animation when the toast is removed
  let scaleTime = 0;

  const alertTypes: Record<Toast["type"], string> = {
    info: "border-info text-info",
    success: "border-success text-success",
    warning: "border-warning text-warning",
    error: "border-error text-error",
    deleted: "border-error text-error",
  };

  const alertIcons: Record<Toast["type"], ComponentType> = {
    info: CheckIcon,
    success: CheckIcon,
    warning: WarningIcon,
    error: Close,
    deleted: TrashcanClosedIcon,
  };
</script>

<div
  class="bg-white border-[1px] shadow-lg p-4 rounded-2xl text-lg {alertTypes[
    t.type
  ]} flex justify-between grow max-w-md"
  out:scale={{ duration: scaleTime }}
>
  <div class="flex items-center gap-4 text-inherit">
    <!-- icon -->
    <svelte:component this={alertIcons[t.type]} size="1.5rem" />

    <div class="whitespace-pre-wrap text-base-100">
      {t.message}
    </div>
  </div>

  <!-- duration_ms -->
  {#if t.duration_ms}
    <!-- Only set the timeout when the toast is rendered. This allows it to work with showOnRoutes -->
    {@const _timer = setTimeout(() => {
      toast.remove(t.id);
    }, t.duration_ms)}
  {/if}
</div>
