<script lang="ts">
  import { onMount } from "svelte";
  import { toast } from "../stores/toast.js";
  import SingleToast from "./SingleToast.svelte";

  /** Align the toast along the x and y axes */
  export let alignment: {
    x?: "start" | "center" | "end";
    y?: "top" | "middle" | "bottom";
  } = {};

  const resolvedAlignment = {
    x: alignment.x ?? "end",
    y: alignment.y ?? "bottom",
  };

  const toastAlignments = {
    start: "toast-start",
    center: "toast-center",
    end: "toast-end",
    top: "toast-top",
    middle: "toast-middle",
    bottom: "toast-bottom",
  };
  let modal: HTMLDialogElement | undefined;
  function appendToBody(node: HTMLElement) {
    if (modal) {
      modal.appendChild(node);
    } else {
      document.body.appendChild(node);
    }
    return {
      destroy() {
        return;
      },
    };
  }

  const observer = new MutationObserver((mutationsList, observer) => {
    mutationsList.forEach((mutation) => {
      let node = mutation.target as HTMLDialogElement;
      if (mutation.type === "attributes" && mutation.attributeName === "open") {
        if (node.open) {
          modal = node;
        } else {
          modal = undefined;
        }
      }
    });
  });

  onMount(() => {
    observer.observe(document.body, { attributes: true, subtree: true });
    return () => observer.disconnect();
  });
</script>

{#if modal}
  <div
    class="toast mb-20 {toastAlignments[resolvedAlignment.x]} {toastAlignments[
      resolvedAlignment.y
    ]}"
    use:appendToBody
  >
    {#each $toast as t (t.id)}
      <SingleToast {t} />
    {/each}
  </div>
{:else}
  <div
    class="toast mb-20 {toastAlignments[resolvedAlignment.x]} {toastAlignments[
      resolvedAlignment.y
    ]}"
  >
    {#each $toast as t (t.id)}
      <SingleToast {t} />
    {/each}
  </div>
{/if}
